export const SIGNUP_PLACEHOLDER = {
  GITHUB_ID: {
    DEFAULT_PLACEHOLDER: '깃허브 닉네임',
    ERROR_PLACEHOLDER: '닉네임은 2글자 이상이어야 합니다',
  },
  PASSWORD: {
    DEFAULT_PLACEHOLDER: '비밀번호',
    ERROR_PLACEHOLDER: '비밀번호는 4글자 이상이어야 합니다',
  },
  CHECKED_PASSWORD: {
    DEFAULT_PLACEHOLDER: '비밀번호 확인',
    ERROR_PLACEHOLDER: '비밀번호와 일치하지 않습니다.',
  },
  NAME: {
    DEFAULT_PLACEHOLDER: '이름',
    ERROR_PLACEHOLDER: '이름은 2글자 이상이어야 합니다',
  },
  STUDENT_ID: {
    DEFAULT_PLACEHOLDER: '학번',
    ERROR_PLACEHOLDER: '10자리의 학번 형식이어야 합니다.',
  },
  BAEKJOON_ID: {
    DEFAULT_PLACEHOLDER: '백준 닉네임',
    ERROR_PLACEHOLDER: '백준 닉네임은 2글자 이상이어야 합니다',
  },
  DISCORD_ID: {
    DEFAULT_PLACEHOLDER: '디스코드 아이디',
    ERROR_PLACEHOLDER: '디스코드 아이디는 2글자 이상이어야 합니다',
  },
  NOTION_EMAIL: {
    DEFAULT_PLACEHOLDER: '노션 이메일',
    ERROR_PLACEHOLDER: '이메일 형식이 잘못됐습니다',
  },
};
